import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getFromStorage } from "./constants/storage";
import { STORAGE_KEYS } from "./constants/storageKeys";

const ProtectedRoutes: React.FC = () => {
  // const tokenFromRed = useAppSelector(getToken);
  const token = getFromStorage(STORAGE_KEYS.token);
  let auth = !!token;
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
