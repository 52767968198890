/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  useLazyGetUserQuery,
  usePostLogoutMutation,
} from "../../../service/auth";
import { getFromStorage } from "../../../constants/storage";
import {
  getToken,
  resetAuth,
  role,
  setCredentials,
} from "../../../reducers/authSlice";
import { showError, showToast } from "../../../constants/toast";
import { STORAGE_KEYS } from "../../../constants/storageKeys";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { useDebounce } from "../../../constants/useDebounce";
import { useGetSearchQueryQuery } from "../../../service/parentDashboard";
import StarIcon from "@mui/icons-material/Star";
import useAuth from "../../../hooks/useAuth";
import LogoutModal from "../../../Modals/logout";
import { useLazyGetNotificationsQuery } from "../../../service/tutorApi";

const ParentHeader = () => {
  const navigate = useNavigate();
  const [getProfile] = useLazyGetUserQuery();
  const [logout] = usePostLogoutMutation();
  const [open, setOpen] = React.useState(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const debounceValue = useDebounce(query, 750);
  const userDetails = useAuth();
  const token = getFromStorage(STORAGE_KEYS.token)

  const { data, isLoading, isSuccess, isError } = useGetSearchQueryQuery(
    {
      query: debounceValue,
    },
    { skip: !debounceValue }
  );
  const [getNotifications] = useLazyGetNotificationsQuery();
  const [notifications, setNotifcations] = useState<any>([]);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const dispatch = useAppDispatch();
  const tokenFromRed = useAppSelector(getToken);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openLog, setOpenLog] = useState<boolean>(false);
  const handleCloseLog = () => {
    setOpenLog(false);
  };

  const [notiType, setNotiType] = React.useState("0");
  const handleChange = (event: SelectChangeEvent) => {
    setNotiType(event.target.value as string);
  };
  const fetchUser = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const res = await getProfile({}).unwrap();
      if (res?.statusCode === 200) {
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: token || null,
          })
        );
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, []);

  useEffect(() => {
    if (tokenFromRed) {
      fetchUser();
    }
  }, [tokenFromRed]);

  const fetchNotification = async () => {
    try {
      const res = await getNotifications({}).unwrap();
      if (res?.statusCode === 200) {
        setNotifcations(res?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const DrawerList = (
    <Box className="aside_inner" role="presentation">
      <div className="aside_top">
        <figure onClick={() => navigate("/parent/search-result")}>
          <img src={`/static/images/logo_favicon.svg`} alt="logo" />
        </figure>
        <button onClick={toggleDrawer(false)}>
          <CloseIcon />
        </button>
      </div>
      <button
        className="aside_profile"
        onClick={() => navigate("/parent/profile")}
      >
        <figure>
          <img
            src={
              userDetails?.image
                ? userDetails?.image
                : `/static/images/user.png`
            }
            alt="logo"
          />
        </figure>
        <p>
          <strong>{userDetails?.name ? userDetails?.name : "-"}</strong>
          <span>{userDetails?.address ? userDetails?.address : "-"}</span>
        </p>
        <ArrowForwardIosIcon />
      </button>
      <div className="aside_menu">
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/my-bookings")}>
              <figure>
                <img src={`/static/images/booking_icon.svg`} alt="Icon" />
              </figure>
              <p>My Bookings</p>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/chat")}>
              <figure>
                <img src={`/static/images/chat_icon.svg`} alt="Icon" />
              </figure>
              <p>My Chats</p>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/pairing")}>
              <figure>
                <img src={`/static/images/pairing_icon.svg`} alt="Icon" />
              </figure>
              <p>My Pairing</p>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/study-material")}>
              <figure>
                <img src={`/static/images/study_icon.svg`} alt="Icon" />
              </figure>
              <p>My Study Material</p>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/my-address")}>
              <figure>
                <img src={`/static/images/address_icon.svg`} alt="Icon" />
              </figure>
              <p>My Address</p>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/contact-us")}>
              <figure>
                <img src={`/static/images/phone_icon.svg`} alt="Icon" />
              </figure>
              <p>Contact Us</p>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate("/parent/terms-and-conditions")}
            >
              <figure>
                <img src={`/static/images/cms_icon.svg`} alt="Icon" />
              </figure>
              <p>Terms & Condition</p>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/faq")}>
              <figure>
                <img src={`/static/images/faq_icon.svg`} alt="Icon" />
              </figure>
              <p>FAQ</p>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/parent/about-us")}>
              <figure>
                <img src={`/static/images/about_icon.svg`} alt="Icon" />
              </figure>
              <p>About</p>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider className="mt_auto" />
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              //  onClick={() => navigate('/auth/as-parent/login')}
              onClick={() => setOpenLog(true)}
            >
              <figure>
                <img src={`/static/images/signout_icon.svg`} alt="Icon" />
              </figure>
              <p>Sign Out</p>
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </Box>
  );

  const fetchProfile = () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.user);

    if (token) {
      dispatch(
        setCredentials({
          user: userData || null,
          token: token || null,
        })
      );
      dispatch(role({ roleName: "parent" }));
    }
  };


  useEffect(() => {
    fetchProfile();
  }, []);
  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token) {
      fetchNotification();
    }
  }, []);

  return (
    <>
      <header className="site_header v2">
        <div className="main_header">
          <div className="conta_iner v2">
            <nav>
              {token ? (
                <IconButton
                  className="icon_btn site_hamburgur"
                  onClick={toggleDrawer(true)}
                >
                  <span></span>
                </IconButton>
              ) : ("")}

              <a onClick={() => navigate("/")} className="site_logo">
                <figure>
                  <img src={`/static/images/logo.png`} alt="logo" />
                </figure>
              </a>
              <div className="icon_flex">
                {showInput && (
                  <TextField
                    className="search-input"
                    hiddenLabel
                    fullWidth
                    placeholder="Search here..."
                    variant="outlined"
                    name="search"
                    type="text"
                    id="search"
                    inputProps={{ maxLength: 80 }}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                )}

                {isSuccess ? (
                  <Paper
                    style={{
                      position: "absolute",
                      top: "75px",
                      width: "28%",
                      zIndex: 999,
                      right: "140px",
                      height: "fit-content", // Fixed height for the dropdown
                      maxHeight: "360px",
                      overflowY: "auto",
                    }}
                    elevation={3}
                  >
                    <List>
                      {data?.data?.tutor?.length ? (
                        data?.data?.tutor?.map((item, index) => (
                          <ListItem
                            key={index}
                            style={{}}
                            className="search-result"
                            onClick={() =>
                              navigate(`/parent/tutor-detail/${item?._id}`)
                            }
                          >
                            {/* Left Side: Avatar */}
                            <Box marginRight="20px" padding={"12px"}>
                              {" "}
                              {/* Adds some space between image and text */}
                              <ListItemAvatar>
                                <Avatar
                                  alt={item.name}
                                  src={item.image}
                                  style={{
                                    width: "60px",
                                    height: "60px", // Bigger avatar for better visual impact
                                    borderRadius: "4px", // Slight rounding for a modern look
                                  }}
                                />
                              </ListItemAvatar>
                            </Box>

                            <Box flex="1">
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                {/* Tutor Name and Subjects */}
                                <Box>
                                  <h2>{item.name}</h2>
                                  <p>
                                    {item?.classCount
                                      ? item.classCount > 99
                                        ? "99+ classes"
                                        : `${item.classCount} ${item.classCount > 1 ? "classes" : "class"}`
                                      : ""}
                                  </p>
                                  <ins>${item?.price || ""}/hour</ins>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#FFB400",
                                    }}
                                  >
                                    <StarIcon
                                      fontSize="inherit"
                                      style={{ marginRight: "2px" }}
                                    />
                                    {item?.avgRating?.toFixed(1) || ""}
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="250px" // Takes up the full height of the Paper
                        >
                          <h2 style={{ fontSize: "14px" }}>no matches found</h2>
                        </Box>
                      )}
                    </List>
                  </Paper>
                ) : null}

                {token ? (
                  <>
                    {showInput ? (
                      <IconButton
                        className="icon_btn"
                        onClick={() => {
                          setQuery("");
                          setShowInput(false);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="icon_btn"
                        onClick={() => setShowInput(true)}
                      >
                        <figure>
                          <img src={`/static/images/search_icon.svg`} alt="img" />
                        </figure>
                      </IconButton>
                    )}

                    <IconButton
                      className="icon_btn"
                      onClick={() => navigate("/parent/wishlist")}
                    >
                      <figure>
                        <img src={`/static/images/wishlist_icon.svg`} alt="img" />
                      </figure>
                    </IconButton>

                    <IconButton
                      className="icon_btn"
                      id="notification-button"
                      aria-controls={open1 ? "notification-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open1 ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <figure>
                        <img src={`/static/images/bell_icon.svg`} alt="img" />
                      </figure>
                    </IconButton>
                  </>
                ) : null}

                <Menu
                  className="noti_box"
                  id="notification-menu"
                  anchorEl={anchorEl}
                  open={open1}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "notification-button",
                  }}
                >
                  <div className="head">
                    <div className="lt_s">
                      <h2>Notifications</h2>
                    </div>
                    {/* <p>
                      Mark all as read <CheckCircleOutlineIcon />
                    </p> */}
                  </div>
                  <ul className="body">
                    {notifications?.newNotification?.length ? (
                      <h6>Recent notifications</h6>
                    ) : (
                      ""
                    )}

                    {notifications?.newNotification?.length
                      ? notifications?.newNotification?.map((item: any) => {
                        return (
                          <>
                            <li
                              onClick={() => navigate("/parent/my-bookings")}
                              style={{
                                backgroundColor: "lightgray",
                                padding: "8px",
                                borderRadius: "15px",
                              }}
                            >
                              <p>
                                <strong>{item?.title}</strong> {item?.message}
                              </p>
                            </li>
                          </>
                        );
                      })
                      : ""}

                    {notifications?.oldNotification?.length ? (
                      <h6>Older notifications</h6>
                    ) : (
                      ""
                    )}
                    {notifications?.oldNotification?.length
                      ? notifications?.oldNotification?.map((item: any) => {
                        return (
                          <>
                            <li
                              onClick={() => navigate("/parent/my-bookings")}
                              style={{ padding: "8px", borderRadius: "15px" }}
                            >
                              <p>
                                <strong>{item?.title}</strong> {item?.message}
                              </p>
                            </li>
                          </>
                        );
                      })
                      : ""}

                    {/* <li>
                                            <figure className="online">
                                                <img src={`/static/images/parent_profile_img.png`} alt="logo" />
                                            </figure>
                                            <p><strong>Catherine Bell</strong>  has started the job.</p>
                                            <Button><img src={`/static/images/map_icon.svg`} alt="Icon" /> Track Location</Button>
                                            <p><small>Today at 9:40AM</small></p>
                                        </li> */}
                  </ul>
                </Menu>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <LogoutModal
        open={openLog}
        setOpen={setOpenLog}
        onClose={handleCloseLog}
      />

      <Drawer
        className="header_aside"
        open={open}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
    </>
  );
};

export default ParentHeader;
