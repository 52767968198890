/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { TutorLayout } from "../../../layout/tutorLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { useLazyGetBookingsQuery } from "../../../service/tutorApi";
import { showError } from "../../../constants/toast";
import Pagination from "../../../constants/Pagination";
import Loader from "../../../constants/Loader";
import moment from "moment";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TutorBookings() {
  const navigate = useNavigate();
  const location = useLocation();
  const {state}=location;
  const [value, setValue] = React.useState(0);
  const [bookings, setBookings] = useState<any>([]);
  const [bookingsApi] = useLazyGetBookingsQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(count / 10);

  const fetchBookings = async () => {
    try {
      setIsLoading(true);
      const res = await bookingsApi({
        bookingType: value === 0 ? 1 : value === 1 ? 3 : 2,
        page: page,
      }).unwrap();
      setIsLoading(false);
      if (res?.statusCode === 200) {
        setBookings(res?.data?.booking);
        setCount(res?.data?.totalBooking);
      }
    } catch (error: any) {
      setIsLoading(false);
      showError(error?.data?.message);
    }
  };

  const getClassName = (item: any) => {
    if (item?.bookingStatus === 1) {
      return "booking_item pending";
    } else if (item?.bookingStatus === 2) {
      return "booking_item accepted";
    } else if (item?.bookingStatus === 3) {
      return "booking_item completed";
    } else if (item?.bookingStatus === 5) {
      return "booking_item cancelled";
    } else if (item?.bookingStatus === 6) {
      return "booking_item ongoing";
    } else {
      return "booking_item ongoing";
    }
  };

  const statusCheck = (num: number): string => {
    switch (num) {
      case 1:
        return "PENDING";
      case 2:
        return "ACCEPTED";
      case 3:
        return "COMPLETED";
      case 4:
        return "REJECTED";
      case 5:
        return "CANCELLED";
      case 6:
        return "ONGOING";
      case 7:
        return "UPCOMING";
      default:
        return "UNKNOWN";
    }
  };

  const navigationLink = (item: any): string => {
    console.log(item, "item in navigate");

    switch (item?.bookingStatus) {
      case 1:
        return `/tutor/booking-detail/pending/${item?._id}`;
      case 2:
        return `/tutor/booking-detail/accepted/${item?._id}`;
      case 3:
        return `/tutor/booking-detail/completed/${item?._id}`;
      case 4:
        return `/tutor/booking-detail/cancelled/${item?._id}`;
      case 5:
        return `/tutor/booking-detail/cancelled/${item?._id}`;
      case 6:
        return `/tutor/booking-detail/ongoing/${item?._id}`;
      default:
        return `/tutor/booking-detail/ongoing/${item?._id}`;
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

useLayoutEffect(()=>{
  if(state){
    setValue(state?.tab-1)
  }
},[])
  

  useEffect(() => {
    fetchBookings();
  }, [value, page]);

  return (
    <>
      <TutorLayout className="role-layout">
        <Loader isLoad={isLoading} />
        <main className="content">
          <section className="uhb_spc tBooking_sc">
            <div className="conta_iner v2">
              <div className="role_head">
                <button
                  className="back_arrow"
                  onClick={() => navigate("/tutor/dashboard")}
                >
                  <img src={`/static/images/back.png`} alt="Back" />
                </button>
                <h1 className="hd_3">My Bookings</h1>
              </div>
              <div className="role_body">
                <Tabs
                  className="custom_tabs"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Upcoming" {...a11yProps(0)} />
                  <Tab label="Ongoing" {...a11yProps(1)} />
                  <Tab label="Past" {...a11yProps(2)} />
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                  <div className="gap_m">
                    {bookings?.length ? (
                      bookings.map((item: any, index: number) => {
                        return (
                          <div
                            className={getClassName(item)}
                            onClick={() => navigate(navigationLink(item))}
                            key={index}
                          >
                            <ul className="top">
                              <li>
                                <span>Date :</span>
                                <strong>
                                  {moment(
                                    item?.bookingdetails?.[0]?.date
                                  ).format("DD/MM/YYYY") || "-"}
                                </strong>
                              </li>
                              <li>
                              <span>Mode :</span>
                              <strong>
                                {item?.classModeOnline ? "Online" : "Offline"}
                              </strong>
                            </li>
                              <li>
                                <span>Time :</span>
                                <strong>
                                  {moment(
                                    item?.bookingdetails?.[0]?.startTime
                                  ).format("hh:mmA")}{" "}
                                  -{" "}
                                  {moment(
                                    item?.bookingdetails?.[0]?.endTime
                                  ).format("hh:mmA") || "-"}
                                </strong>
                              </li>
                            </ul>
                            <div className="infoBox">
                              <figure>
                                <img
                                  src={
                                    item?.parents?.image
                                      ? item?.parents?.image
                                      : `/static/images/user.png`
                                  }
                                  alt="Image"
                                />
                              </figure>
                              <h2>
                                {item?.parents?.name ? item?.parents.name : "-"}
                              </h2>
                              <ul>
                                <li>
                                  <span>Subject</span>
                                  <strong>
                                    {item?.subjectspecializations?.[0]?.name
                                      ? item?.subjectspecializations?.[0]?.name
                                      : "-"}
                                  </strong>
                                </li>
                              </ul>
                              <p className="status">
                                {statusCheck(item?.bookingStatus)}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no_data">
                        <figure>
                          <img
                            src="/static/images/noData.png"
                            alt="no data found"
                          />
                        </figure>
                        <p>No booking found</p>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="gap_m">
                    {bookings?.length ? (
                      bookings.map((item: any, index: number) => {
                        return (
                          <div
                            className={getClassName(item)}
                            onClick={() => navigate(navigationLink(item))}
                            key={index}
                          >
                            <ul className="top">
                              <li>
                                <span>Date :</span>
                                <strong>
                                  {moment(
                                    item?.bookingdetails?.[0]?.date
                                  ).format("DD/MM/YYYY") || "-"}
                                </strong>
                              </li>
                              <li>
                              <span>Mode :</span>
                              <strong>
                                {item?.classModeOnline ? "Online" : "Offline"}
                              </strong>
                            </li>
                              <li>
                                <span>Time :</span>
                                <strong>
                                  {moment(
                                    item?.bookingdetails?.[0]?.startTime
                                  ).format("hh:mmA")}{" "}
                                  -{" "}
                                  {moment(
                                    item?.bookingdetails?.[0]?.endTime
                                  ).format("hh:mmA") || "-"}
                                </strong>
                              </li>
                            </ul>
                            <div className="infoBox">
                              <figure>
                                <img
                                  src={
                                    item?.parents?.image
                                      ? item?.parents?.image
                                      : `/static/images/user.png`
                                  }
                                  alt="Image"
                                />
                              </figure>
                              <h2>
                                {item?.parents?.name ? item?.parents.name : "-"}
                              </h2>
                              <ul>
                                <li>
                                  <span>Subject</span>
                                  <strong>
                                    {item?.subjectspecializations?.[0]?.name
                                      ? item?.subjectspecializations?.[0]?.name
                                      : "-"}
                                  </strong>
                                </li>
                              </ul>
                              <p className="status">
                                {statusCheck(item?.bookingStatus)}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no_data">
                        <figure>
                          <img
                            src="/static/images/noData.png"
                            alt="no data found"
                          />
                        </figure>
                        <p>No booking found</p>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <div className="gap_m">
                    {bookings?.length ? (
                      bookings.map((item: any, index: number) => {
                        return (
                          <div
                            className={getClassName(item)}
                            onClick={() => navigate(navigationLink(item))}
                            key={index}
                          >
                            <ul className="top">
                              <li>
                                <span>Date :</span>
                                <strong>
                                  {moment(
                                    item?.bookingdetails?.[0]?.date
                                  ).format("DD/MM/YYYY") || "-"}
                                </strong>
                              </li>
                              <li>
                              <span>Mode :</span>
                              <strong>
                                {item?.classModeOnline ? "Online" : "Offline"}
                              </strong>
                            </li>
                              <li>
                                <span>Time :</span>
                                <strong>
                                  {moment(
                                    item?.bookingdetails?.[0]?.startTime
                                  ).format("hh:mmA")}{" "}
                                  -{" "}
                                  {moment(
                                    item?.bookingdetails?.[0]?.endTime
                                  ).format("hh:mmA") || "-"}
                                </strong>
                              </li>
                            </ul>
                            <div className="infoBox">
                              <figure>
                                <img
                                  src={
                                    item?.parents?.image
                                      ? item?.parents?.image
                                      : `/static/images/user.png`
                                  }
                                  alt="Image"
                                />
                              </figure>
                              <h2>
                                {item?.parents?.name ? item?.parents.name : "-"}
                              </h2>
                              <ul>
                                <li>
                                  <span>Subject</span>
                                  <strong>
                                    {item?.subjectspecializations?.[0]?.name
                                      ? item?.subjectspecializations?.[0]?.name
                                      : "-"}
                                  </strong>
                                </li>
                              </ul>
                              <p className="status">
                                {statusCheck(item?.bookingStatus)}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no_data">
                        <figure>
                          <img
                            src="/static/images/noData.png"
                            alt="no data found"
                          />
                        </figure>
                        <p>No booking found</p>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>

                {bookings?.length ? (
                  <Pagination
                    module={bookings}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </main>
      </TutorLayout>
    </>
  );
}
