// import React, { useEffect, useState } from "react";
// import uitoolkit from "@zoom/videosdk-ui-toolkit";
// import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
// import generateJwt from "../../utils/jwt";
// import "./Zoom.scss";
// import { useLocation, useNavigate, useParams } from "react-router-dom";

// const ZoomCall = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const type = queryParams.get("type");
//   const { state } = location;
// console.log(state,"state");


//   const [sessionContainer, setSessionContainer] = useState<HTMLElement | null>(
//     null
//   );

//   useEffect(() => {
//     const joinZoomSession = async () => {
//       const sessionName = state?.data?.sessionName || "";
//       const displayName = state?.data?.displayName || "User";
//       const roleType = state?.data?.roleType || "1";
//       const sessionIdleTimeoutMins =
//         state?.data?.sessionIdleTimeoutMins || "10";
//       console.log(roleType, "state");

//       try {
//         // Generate JWT
//         const token = await generateJwt(sessionName, roleType);

//         if (!token) {
//           navigate(`/${type}/booking-detail/ongoing/${id}`, { replace: true });

//           // Alert.alert("Failed to generate token");
//           // return navigation.goBack(); // Simulate navigation back
//         }

//         // Fetch or set session container
//         const container = document.getElementById("sessionContainer");
//         if (!container) {
//           // Alert.alert("Session container not found");
//           navigate(`/${type}/booking-detail/ongoing/${id}`, { replace: true });

//           return;
//         }

//         setSessionContainer(container);

//         // Set display to none for the join flow UI
//         const joinFlow = document.getElementById("join-flow");
//         if (joinFlow) {
//           joinFlow.style.display = "block";
//         } else {
//           console.warn("'join-flow' element not found");
//         }

//         // Join session using the Zoom SDK
//         await uitoolkit.joinSession(container, {
//           videoSDKJWT: token,
//           sessionName,
//           userName: displayName,
//           features: [
//             "video",
//             "audio",
//             "settings",
//             "users",
//             "chat",
//             "share",
//             "reactions",
//           ],
//           options: {
//             init: {},
//             audio: {
//               connect: true,
//               mute: true,
//               autoAdjustSpeakerVolume: false,
//             },
//             video: {
//               localVideoOn: true,
//             },
//             share: {
//               mirror: false,
//             },
//             reactions: {
//               // Explicitly configure reactions if needed
//               enable: true,
//             },
//           },
//           sessionIdleTimeoutMins: parseInt(sessionIdleTimeoutMins, 10),
//         });

//         uitoolkit.onSessionClosed(() => {
//           sessionClosed();
//         });
//       } catch (e) {
//         console.error("Error joining session:", e);

//         navigate(`/${type}/booking-detail/ongoing/${id}`, { replace: true });
//       }
//     };

//     joinZoomSession();

//     return () => {
//       if (sessionContainer) {
//         uitoolkit.closeSession(sessionContainer);
//         const joinFlow = document.getElementById("join-flow");
//         if (joinFlow) {
//           joinFlow.style.display = "block";
//         } else {
//           console.warn("'join-flow' element not found");
//           navigate(`/${type}/booking-detail/ongoing/${id}`, { replace: true });
//         }
//       }
//     };
//   }, [state]);

//   const sessionClosed = () => {
//     console.log("Session closed");
//     navigate(`/${type}/booking-detail/ongoing/${id}`, { replace: true });

//     if (sessionContainer) {
//       uitoolkit.closeSession(sessionContainer);
//       const joinFlow = document.getElementById("join-flow");

//       if (joinFlow) {
//         joinFlow.style.display = "block";
//       } else {
//         console.warn("'join-flow' element not found");
//       }
//     }
//   };

//   return (
//     <div id="join-flow">
//       <div id="sessionContainer"></div>
//     </div>
//   );
// };

// export default ZoomCall;


import React, { useEffect, useState } from "react";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import generateJwt from "../../utils/jwt";
import "./Zoom.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ZoomCall = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const { state } = location;

  const [sessionContainer, setSessionContainer] = useState<HTMLElement | null>(
    null
  );
  const [isSessionActive, setIsSessionActive] = useState(false);

  useEffect(() => {
    const joinZoomSession = async () => {
      if (isSessionActive) {
        console.warn("Session is already active.");
        return;
      }

      const sessionName = state?.data?.sessionName || "";
      const displayName = state?.data?.displayName || "User";
      const roleType = state?.data?.roleType || "1";
      const sessionIdleTimeoutMins =
        state?.data?.sessionIdleTimeoutMins || "10";

      try {
        // Generate JWT
        const token = await generateJwt(sessionName, roleType);

        if (!token) {
          navigate(`/${type}/booking-detail/accepted/${id}`, { replace: true });
          return;
        }

        // Fetch or set session container
        const container = document.getElementById("sessionContainer");
        if (!container) {
          navigate(`/${type}/booking-detail/accepted/${id}`, { replace: true });
          return;
        }

        setSessionContainer(container);

        // Join session using the Zoom SDK
        await uitoolkit.joinSession(container, {
          videoSDKJWT: token,
          sessionName,
          userName: displayName,
          features: [
            "video",
            "audio",
            "settings",
            "users",
            "chat",
            "share",
            "reactions",
          ],
          options: {
            init: {},
            audio: {
              connect: true,
              mute: true,
              autoAdjustSpeakerVolume: false,
            },
            video: {
              localVideoOn: true,
            },
            share: {
              mirror: false,
            },
            reactions: {
              enable: true,
            },
          },
          sessionIdleTimeoutMins: parseInt(sessionIdleTimeoutMins, 10),
        });

        setIsSessionActive(true);

        uitoolkit.onSessionClosed(() => {
          sessionClosed();
          navigate(`/${type}/booking-detail/accepted/${id}`, { replace: true });
        });
      } catch (e) {
        console.error("Error joining session:", e);
        navigate(`/${type}/booking-detail/accepted/${id}`, { replace: true });
      }
    };

    joinZoomSession();

    return () => {
      if (sessionContainer) {
        uitoolkit.closeSession(sessionContainer);
        setIsSessionActive(false);
        navigate(`/${type}/booking-detail/accepted/${id}`, { replace: true });
      }
    };
  }, [state, isSessionActive]);

  const sessionClosed = () => {
    console.log("Session closed");
    setIsSessionActive(false);
    navigate(`/${type}/booking-detail/accepted/${id}`, { replace: true });

    if (sessionContainer) {
      uitoolkit.closeSession(sessionContainer);
      navigate(`/${type}/booking-detail/accepted/${id}`, { replace: true });

    }
  };

  return (
    <div id="join-flow">
      <div id="sessionContainer"></div>
    </div>
  );
};

export default ZoomCall;
