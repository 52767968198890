/* eslint-disable jsx-a11y/img-redundant-alt */
import { ParentLayout } from "../../../layout/parentLayout";
import { Box, Card, CardContent, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterSidebar from "../common/filterSidebar";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useGetRecommendedTutorListingMutation } from "../../../service/parentDashboard";
import { showError } from "../../../constants/toast";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useEffect, useState } from "react";
import Pagination from "../../../constants/Pagination";
import useManageWishList from "../../../apiHooks/useManageWishlisht";
import { useFilters } from "../../../context/filterContext";
export default function ParentPopularTutor() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [getData] = useGetRecommendedTutorListingMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const { filters, value, time, selectedSubjects } = useFilters(); // context for the filter state

  const [data, setData] = useState<any>([]);

  const { handleWishList } = useManageWishList();
  // onChange handler for the page
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top after a brief delay
    }, 0);
  };
  let totalPages = Math.ceil(count / 20);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const subjectsFilter = selectedSubjects?.map((item) => ({ name: item }));

      let body = {
        ...(filters?.experience
          ? { totalTeachingExperience: Number(filters.experience) }
          : null),
        ...(filters?.curriculam?.length
          ? { curriculum: filters?.curriculam }
          : null),
        ...(filters?.teachingStyle?.length
          ? { teachingStyle: filters?.teachingStyle }
          : null),
        ...(filters?.verification
          ? {
              documentVerification:
                Number(filters?.verification) === 1 ? true : false,
            }
          : null),
        ...(filters?.lang ? { teachingLanguage: Number(filters?.lang) } : null),
        ...(filters?.gender ? { gender: filters?.gender } : null),
        ...(filters?.rating ? { avgRating: Number(filters?.rating) } : null),
        ...(value[0] > 5 ? { startPrice: value[0] } : null),
        ...(value[1] < 1000? { endPrice: value[1] } : null),
        ...(time?.startTime ? { startTime: time?.startTime } : null),
        ...(time?.endTime ? { endTime: time?.endTime } : null),
        ...(filters?.grade?.length
          ? { classes: filters?.grade?.map((data) => ({ name: data })) }
          : null),
        ...(selectedSubjects?.length > 0 ? { subjects: subjectsFilter } : null),
        ...(filters?.latitude ? { latitude: filters.latitude } : null),
        ...(filters?.longitude ? { longitude: filters.longitude } : null),
      };
    
      const limit = 20;
      const res = await getData({
        page,
        body,
        limit,
      }).unwrap();
      setIsLoading(false);

      if (res?.statusCode === 200) {
        setData(res?.data?.tutor);
        setCount(res?.data?.totalTutor);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);
  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uhb_spc pTutor_sc">
            <div className="conta_iner v2">
              <div className="role_head">
                <button
                  className="back_arrow"
                  onClick={() => navigate("/parent/search-result")}
                >
                  <img src={`/static/images/back.png`} alt="Back" />
                </button>
                <h1 className="hd_3">Recommended Tutors</h1>
              </div>

              {/* <div className="location_bar">
                <figure>
                  <img src={`/static/images/address_icon.svg`} alt="Icon" />
                </figure>
                <h1>New York, United States</h1>
                <Box component="a">Change</Box>
              </div> */}
              <div className="parent_dash">
                <div className="gap_m">
                  <FilterSidebar fetchData={fetchData} setPage={setPage} />
                  <div className="rt_s">
                    <div className="tutor_list gap_m">
                      {isLoading &&
                        Array.from(new Array(10))?.map((item, index) => (
                          <TutorCardSkeleton />
                        ))}
                      {data?.length ? (
                        data?.map((item: any, index: number) => (
                          <div className="tutor_item" key={item?._id}>
                            <figure>
                              <img
                                src={
                                  item?.image && item?.image !== ""
                                    ? item?.image
                                    : "/static/images/card1.png"
                                }
                                alt="tutor image"
                              />

                              <span className="t_rating">
                                <StarIcon />{" "}
                                {item?.avgRating?.toFixed(1) || "0"}
                              </span>
                              <span
                                className="t_fav"
                                onClick={() => {
                                  handleWishList(item);
                                }}
                              >
                                {item?.isFav ? (
                                  <FavoriteIcon />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}
                              </span>
                              {item?.documentVerification ? (
                                <span className="t_verify">
                                  <img
                                    src="/static/images/verified.png"
                                    alt="img"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </figure>
                            <div
                              className="tutor_info"
                              onClick={() =>
                                navigate(`/parent/tutor-detail/${item?._id}`)
                              }
                            >
                              <h2>{item?.name || ""}</h2>
                              <p>
                                {item?.classCount
                                  ? item.classCount > 99
                                    ? "99+ classes"
                                    : `${item.classCount} ${item.classCount > 1 ? "classes" : "class"}`
                                  : ""}
                              </p>
                              <p>{item?.subjects?.join(" , ")}</p>
                              <ins>${item?.price || ""}/hour</ins>
                            </div>
                          </div>
                        ))
                      ) : isLoading ? (
                        ""
                      ) : (
                        <h3 style={{ textAlign: "center" }}>No Tutor found</h3>
                      )}
                    </div>
                    {data?.length > 0 && (
                      <Pagination
                        module={data}
                        page={page}
                        onPageChange={onPageChange}
                        totalPages={totalPages}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
    </>
  );
}

function TutorCardSkeleton() {
  return (
    <Card sx={{ display: "flex", borderRadius: 3, p: 1, width: "40%" }}>
      {/* Left Image Skeleton */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
        }}
      >
        <Skeleton
          variant="rectangular"
          width={70}
          height={70}
          sx={{ borderRadius: 2 }}
        />
      </Box>

      {/* Right Content Skeleton */}
      <CardContent sx={{ width: "50%" }}>
        {/* Name Skeleton */}
        <Skeleton width="60%" height={30} sx={{ mb: 1 }} />

        {/* Classes Skeleton */}
        <Skeleton width="40%" height={20} sx={{ mb: 1 }} />

        {/* ID Skeleton */}
        <Skeleton width="80%" height={15} sx={{ mb: 1 }} />

        {/* Price Skeleton */}
        <Skeleton width="30%" height={25} />
      </CardContent>
    </Card>
  );
}
